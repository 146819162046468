import {  Response } from 'express';
import { MACDOutput } from 'technicalindicators/declarations/moving_averages/MACD';


import axios from 'axios';
import * as dotenv from 'dotenv';
import express, { Request } from 'express';
import { SMA, EMA, RSI, MACD, BollingerBands, Stochastic, ATR, PSAR, ADX  } from 'technicalindicators';


const BINANCE_API_URL = 'https://api.binance.com/api/v3';



export async function getIndicators(symbol: string, interval: string = '1h', limit: number = 50) {
  try {
    // Convertendo para maiúsculas, caso necessário
    symbol = symbol.toUpperCase();

    // Busca dados históricos (klines)
    const klines = await getKlines(symbol, interval, limit);

    if (!klines || klines.length === 0) {
      throw new Error('Nenhum dado retornado pela API da Binance.');
    }

    // Calcula todos os indicadores
    const indicators = calculateIndicators(klines);

    // Calcula Fibonacci Retracement
    const high = Math.max(...klines.map(kline => parseFloat(kline[2])));
    const low = Math.min(...klines.map(kline => parseFloat(kline[3])));
    const fibonacci = calculateFibonacciRetracement(high, low);

    // Toma decisão com base nos indicadores
    const closes = klines.map(kline => parseFloat(kline[4])); // Preços de fechamento
    const decision = makeDecision(symbol, indicators, closes);

    // Retorna os dados calculados
    return {
      symbol,
      interval,
      decision,
      volumes: indicators.volumes,
      sma: indicators.sma[indicators.sma.length - 1],
      ema: indicators.ema[indicators.ema.length - 1],
      rsi: indicators.rsi[indicators.rsi.length - 1],
      macd: indicators.macd[indicators.macd.length - 1],
      bollingerBands: indicators.bollingerBands[indicators.bollingerBands.length - 1],
      stochastic: indicators.stochastic[indicators.stochastic.length - 1],
      atr: indicators.atr[indicators.atr.length - 1],
      psar: indicators.psar[indicators.psar.length - 1],
      adx: indicators.adx[indicators.adx.length - 1],
      fibonacci,
      divergences: {
        rsi: detectRSIDivergence(closes, indicators.rsi),
        macd: detectMACDDivergence(closes, indicators.macd),
      },
    };
  } catch (error) {
    console.error('Erro ao calcular indicadores técnicos:', error);
    throw new Error(error instanceof Error ? error.message : 'Erro desconhecido');
  }
}





// Função para calcular todos os indicadores
function calculateIndicators(klines: Kline[]) {


    
  const opens = klines.map(kline => parseFloat(kline[1])); // Preços de abertura
  const highs = klines.map(kline => parseFloat(kline[2])); // Preços máximos
  const lows = klines.map(kline => parseFloat(kline[3])); // Preços mínimos
  const closes = klines.map(kline => parseFloat(kline[4])); // Preços de fechamento
  const volumes = klines.map(kline => parseFloat(kline[5])); // Volumes
  const lastVolume = volumes.at(-1); // Último volume registrado
  const averageVolume = volumes.reduce((a, b) => a + b, 0) / volumes.length; // Média dos volumes
  const noOfBars = klines.length;

  const period = 14; // Período comum para muitos indicadores





  return {
 
    volumes: {
        latsVolume: lastVolume,
        averageVolume: averageVolume
      },
    sma: SMA.calculate({ period, values: closes }),
    ema: EMA.calculate({ period, values: closes }),
    rsi: RSI.calculate({ period, values: closes }),
    macd: MACD.calculate({
      values: closes,
      fastPeriod: 12,
      slowPeriod: 26,
      signalPeriod: 9,
      SimpleMAOscillator: false,
      SimpleMASignal: false,
    }),
    bollingerBands: BollingerBands.calculate({
      period,
      values: closes,
      stdDev: 2, // Desvio padrão
    }),
    stochastic: Stochastic.calculate({
      high: highs,
      low: lows,
      close: closes,
      period,
      signalPeriod: 3,
    }),
    atr: ATR.calculate({
      high: highs,
      low: lows,
      close: closes,
      period,
    }),
    psar: PSAR.calculate({
      high: highs,
      low: lows,
      step: 0.02, // Passo do SAR
      max: 0.2, // Máximo do SAR
    }),
    adx: ADX.calculate({
      high: highs,
      low: lows,
      close: closes,
      period,
    }),

  };
}



interface Ticker {
    symbol: string;
    priceChangePercent: string;
    quoteVolume: string;
    lastPrice: string;
  }
  
  // Definindo o tipo para o array de klines
  type Kline = [
    number, // openTime
    string, // open
    string, // high
    string, // low
    string, // close
    string, // volume
    number, // closeTime
    string, // quoteAssetVolume
    number, // numberOfTrades
    string, // takerBuyBaseAssetVolume
    string, // takerBuyQuoteAssetVolume
    string // ignore
  ];
  
  // Função para buscar klines da Binance
  async function getKlines(symbol: string, interval: string, limit: number): Promise<Kline[]> {
    const response = await axios.get(`${BINANCE_API_URL}/klines`, {
      params: { symbol, interval, limit },
    });
    return response.data;
  }
  
  
  
  // Função para calcular Fibonacci Retracement
  function calculateFibonacciRetracement(high: number, low: number) {
    const diff = high - low;
    return {
      level0: high,
      level23_6: high - diff * 0.236,
      level38_2: high - diff * 0.382,
      level50: high - diff * 0.5,
      level61_8: high - diff * 0.618,
      level100: low,
    };
  }
  
  // Função para detectar divergências de RSI
  function detectRSIDivergence(closes: number[], rsi: number[]) {
    const lastClose = closes[closes.length - 1];
    const prevClose = closes[closes.length - 2];
    const lastRsi = rsi[rsi.length - 1];
    const prevRsi = rsi[rsi.length - 2];
  
    // Divergência de alta: preço caindo, RSI subindo
    if (lastClose < prevClose && lastRsi > prevRsi) {
      return 'Bullish Divergence';
    }
  
    // Divergência de baixa: preço subindo, RSI caindo
    if (lastClose > prevClose && lastRsi < prevRsi) {
      return 'Bearish Divergence';
    }
  
    return null;
  }
  
  // Função para detectar divergências de MACD
  function detectMACDDivergence(closes: number[], macdOutput: MACDOutput[]) {
    // Verifica se há dados suficientes para calcular divergências
    if (macdOutput.length < 2) {
      return null; // Não há dados suficientes
    }
  
    const lastClose = closes[closes.length - 1];
    const prevClose = closes[closes.length - 2];
    const lastMacdHistogram = macdOutput[macdOutput.length - 1]?.histogram;
    const prevMacdHistogram = macdOutput[macdOutput.length - 2]?.histogram;
  
    // Verifica se as propriedades histogram existem
    if (lastMacdHistogram === undefined || prevMacdHistogram === undefined) {
      return null; // Dados incompletos
    }
  
    // Divergência de alta: preço caindo, MACD subindo
    if (lastClose < prevClose && lastMacdHistogram > prevMacdHistogram) {
      return 'Bullish Divergence';
    }
  
    // Divergência de baixa: preço subindo, MACD caindo
    if (lastClose > prevClose && lastMacdHistogram < prevMacdHistogram) {
      return 'Bearish Divergence';
    }
  
    return null;
  }
  


  
// Função para tomar decisão com base nos indicadores
function makeDecision(symbol : any, indicators: any, closes: number[]) {
    const { rsi, macd, stochastic, bollingerBands, psar, adx, volumes, ema} = indicators;
  
    //console.log (indicators)
  
    const lastRsi = rsi[rsi.length - 1];
    const lastMacdHistogram = macd[macd.length - 1]?.histogram; // Acessando o histograma corretamente
    const lastStochastic = stochastic[stochastic.length - 1];
    const lastBollinger = bollingerBands[bollingerBands.length - 1];
    const lastPsar = psar[psar.length - 1];
    const lastAdx = adx[adx.length - 1];
    const lastEma = ema[ema.length - 1];
    const close = closes[closes.length - 1]
  
    // Verifica se os indicadores necessários estão disponíveis
    if (
      lastRsi === undefined ||
      lastMacdHistogram === undefined ||
      lastStochastic === undefined ||
      lastPsar === undefined ||
      lastAdx === undefined
    ) {
      return 'SegurarXXX'; // Dados incompletos
    }
  
    // Detectar divergências
    //const rsiDivergence = detectRSIDivergence(closes, rsi);
    //const macdDivergence = detectMACDDivergence(closes, macd);
  
    // Lógica de decisão aprimorada
    const isBullish =
      lastRsi < 40 && // RSI indica sobrevenda (30)
      lastMacdHistogram > -1 && // MACD indica tendência de alta
      lastStochastic.k < 20 && // Stochastic indica sobrevenda
      lastPsar < close && // PSAR indica tendência de alta
      lastAdx.adx > 25 &&  // ADX indica tendência forte
    //  && (rsiDivergence === 'Bullish Divergence' || macdDivergence === 'Bullish Divergence'); // Divergência de alta
      close > lastEma
   //   volumes.lastVolume > volumes.averageVolume // ultimo volume Maior que media de volume
  
    const isBearish =
      lastRsi > 70 && // RSI indica sobrecompra
      lastMacdHistogram < 0 && // MACD indica tendência de baixa
      lastStochastic.k > 80 && // Stochastic indica sobrecompra
      lastPsar > close && // PSAR indica tendência de baixa
      lastAdx.adx > 25 // ADX indica tendência forte
    // &&  (rsiDivergence === 'Bearish Divergence' || macdDivergence === 'Bearish Divergence'); // Divergência de baixa
  
    if (lastRsi < 40 && lastMacdHistogram > -1 && lastStochastic.k < 20 && lastPsar < close   ) {
  console.log (`Symbol: ${symbol} LastrRsi:${lastRsi} LastMacdHistogram:${lastMacdHistogram} lastStochastic.k:${lastStochastic.k} Preço ultimo: ${close} lastPsar:${lastPsar} lastAdx:${lastAdx.adx} lastEma:${lastEma}`)
    } 
  
    if (symbol == 'BTCUSDT') {
    console.log (`Symbol: ${symbol} LastrRsi:${lastRsi} LastMacdHistogram:${lastMacdHistogram} lastStochastic.k:${lastStochastic.k} Preço ultimo: ${close} lastPsar:${lastPsar} lastAdx:${lastAdx.adx} lastEma:${lastEma}`)
    }
  
    if (isBullish) {
      return 'Comprar';
    } else if (isBearish) {
      return 'Vender';
    } else {
      return 'Segurar';
    }
  }
  