import axios from 'axios';

import { getIndicators } from './indicators';




const BINANCE_API_URL = 'https://api.binance.com/api/v3';
const TEMPO = "1h";



// Função para buscar todos os pares de trading com USDT
export async function fetchAllUSDTpairs(): Promise<string[]> {
  try {
    const response = await axios.get(`${BINANCE_API_URL}/exchangeInfo`);
    const symbols = response.data.symbols;

    // Filtra apenas os pares que terminam com USDT
    const usdtPairs = symbols
      .filter((symbol: any) => symbol.symbol.endsWith('USDT'))
      .map((symbol: any) => symbol.symbol);

    return usdtPairs;
  } catch (error) {
    console.error('Erro ao buscar pares de trading com USDT:', error);
    return [];
  }
}

// Função para buscar indicadores técnicos para os pares USDT
export async function fetchAllUSDTPairsWithIndicators(): Promise<string[]> {
  try {
    console.log('Iniciando busca');
    const usdtPairs = await fetchAllUSDTpairs();

    if (usdtPairs.length === 0) {
      throw new Error('Nenhum par de trading com USDT encontrado.');
    }

    const resultados: string[] = [];
    const batchSize = 10; // Processa em lotes de 10

    for (let i = 0; i < usdtPairs.length; i += batchSize) {
      const batch = usdtPairs.slice(i, i + batchSize);

      for (const pair of batch) {
        const response = await getIndicators(pair, TEMPO, 50);

        if (response) {
          resultados.push(`Resultados para ${pair}:\n${JSON.stringify(response, null, 2)}`);
        }
      }

      // Pequeno delay entre os lotes
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    console.log('Busca concluída');
    return resultados;
  } catch (error) {
    console.error('Erro ao buscar indicadores para os pares USDT:', error);
    throw error;
  }
}
