import { useState } from "react";
import { fetchAllUSDTpairs as fetchAllUSDTPairs, fetchAllUSDTPairsWithIndicators } from "../services/processamentoOportunidades";
import { createSolicitation } from "../services/createSolicService";

const ProcessamentoOportunidades = () => {
  const [status, setStatus] = useState<string>("Aguardando início...");
  const [resultados, setResultados] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<string>("");
  const [forceBuy, setForceBuy] = useState<boolean>(false);
  const [buyQty, setBuyQty] = useState<number>(0);
  const [profit, setProfit] = useState<number>(0);
  const [stopLess, setStopLess] = useState<number>(0);
  const [buyRecorrent, setBuyRecorrent] = useState<boolean>(false);  




  
  
  const iniciarProcessamento = async () => {
    setStatus("Processando oportunidades...");
    setIsProcessing(true);
    setResultados([]);

    try {
      const dados = await fetchAllUSDTPairsWithIndicators();

      setResultados(dados);
      setStatus("Processamento concluído!");
    } catch (error) {
      console.error("Erro ao buscar indicadores:", error);
      setStatus("Erro ao processar oportunidades.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="p-6 max-w-2xl mx-auto bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Processamento de Oportunidades</h1>

      <button
        onClick={iniciarProcessamento}
        className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
        disabled={isProcessing}
      >
        {isProcessing ? "Processando..." : "Iniciar Processamento"}
      </button>

      <p className="mt-4 font-semibold">{status}</p>

      <div className="mt-4 p-4 bg-gray-100 rounded h-64 overflow-auto">
        {resultados.length > 0
          ? resultados.map((linha, index) => <pre key={index}>{linha}</pre>)
          : <p className="text-gray-500">Nenhum resultado ainda.</p>}
      </div>
    </div>
  );
};

export default ProcessamentoOportunidades;






