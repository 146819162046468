import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Box, CssBaseline, Card, CardContent, Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Sidebar from './Sidebar';
import axiosInstance from './axiosConfig';

const drawerWidth = 240; // Largura do Sidebar

const Dashboard: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true); // Inicia como true para abrir o sidebar
  const [loading, setLoading] = useState<boolean>(false);
  const [traderSuspended, setTraderSuspended] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Verifica se o usuário está autenticado
  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log (token)
    if (!token) {
      navigate('/login'); // Redireciona para a página de login se não houver token
    }
  }, [navigate]);

  // Busca os dados do usuário
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get('me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setTraderSuspended(response.data.stopTrader);
        setUserName(response.data.name);
      } catch (error) {
        alert('Erro ao buscar o estado do trader.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Limpa o estado ao mudar de rota
  useEffect(() => {
    setTraderSuspended(false);
  }, [location]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleTraderAction = async (stopTrader: boolean) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.put('stopusertrader', { stopTrader }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTraderSuspended(stopTrader);
      alert(`Trader ${stopTrader ? 'suspenso' : 'retomado'} com sucesso.`);
    } catch (error) {
      alert(`Erro ao ${stopTrader ? 'suspender' : 'retomar'} o trader.`);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    handleMenuClose(); // Fecha o menu
    navigate('/dashboard/edituser'); // Redireciona para a página de edição de usuários
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    console.log ("removeu token")
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
          ml: open ? `${drawerWidth}px` : 0,
          transition: 'width 0.3s, margin-left 0.3s',
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleDrawer}
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>

          <Typography variant="body1" sx={{ mr: 2 }}>
            {userName}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleMenuOpen}
          >
            <AccountCircleIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleSettingsClick}>Configurações</MenuItem> {/* Chamando o manipulador para Configurações */}
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Sidebar open={open} toggleDrawer={toggleDrawer} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          transition: 'margin-left 0.3s',
          ml: open ? `${drawerWidth}px` : 0,
        }}
      >
        <Toolbar />

        {location.pathname === '/dashboard' && (
          <Card sx={{ maxWidth: 400, mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {traderSuspended ? 'Trader Suspenso' : 'Trader Ativo'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {traderSuspended
                  ? 'O trader está suspenso. Clique em "Retomar Trader" para reativá-lo.'
                  : 'O trader está ativo. Clique em "Suspender Trader" para pausá-lo.'}
              </Typography>

              <Button
                variant="contained"
                color={traderSuspended ? 'primary' : 'secondary'}
                onClick={() => handleTraderAction(!traderSuspended)}
                disabled={loading}
                sx={{ mt: 2 }}
              >
                {loading ? 'Processando...' : traderSuspended ? 'Retomar Trader' : 'Suspender Trader'}
              </Button>
            </CardContent>
          </Card>
        )}

        {/* Renderiza o conteúdo das rotas filhas */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
