// src/Sidebar.tsx
import React from 'react';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Divider, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface SidebarProps {
  open: boolean;
  toggleDrawer: () => void;
}

interface MenuItem {
  text: string;
  icon: React.ReactNode;
  path: string;
}

const menuItems: MenuItem[] = [
  { text: 'Dashboard', icon: <HomeIcon />, path: '/dashboard' },
  { text: 'List Users', icon: <PeopleIcon />, path: '/listusers' },
  { text: 'Alterar Dados', icon: <AttachMoneyIcon />, path: '/dashboard/edituser' },
  { text: 'Posições Abertas', icon: <ShowChartIcon />, path: '/dashboard/posicaoaberto' },
  { text: 'Todas as Posições', icon: <ListAltIcon />, path: '/dashboard/posicaoall' },
  { text: 'Listar Pairs', icon: <AssignmentIcon />, path: '/dashboard/pairs' },
  { text: 'Solic', icon: <AssignmentIcon />, path: '/dashboard/solic' },
  { text: 'Posição no Período', icon: <AssignmentIcon />, path: '/dashboard/posicaoallPeriodo' },
  { text: 'Posições a Comprar', icon: <AssignmentIcon />, path: '/dashboard/posicaocomprar' },
  { text: 'Oportunidades', icon: <AssignmentIcon />, path: '/dashboard/oportunidades' },
];

const Sidebar: React.FC<SidebarProps> = ({ open, toggleDrawer }) => {
  return (
    <Drawer variant="persistent" open={open}>
      <div>
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItemButton key={item.text} component={Link} to={item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.text}
              sx={{ opacity: open ? 1 : 0 }} // Exibe o texto somente se o Sidebar estiver aberto
            />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
