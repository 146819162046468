// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthContext"; // Importa o AuthProvider
import Login from "./Login";
import Register from "./Register";
import Dashboard from "./Dashboard";
import UserList from "./ListUsers";
import SolicList from "./ListSolic";
import Me from "./Me";
import EditUser from "./EditUser";
import PosicaoAll from "./pages/PosicaoAll";
import CreateSolic from "./pages/CreateSolic";
import ListPairs from "./ListPairs";
import PosicaoAberto from "./pages/PosicaoAberto";
import PosicaoAllPeriodo from "./pages/PosicaoAllPeriodo";
import PosicoesComprar from "./pages/PosicoesComprar";
import Home from "./home";
import ProtectedRoute from "./ProtectedRoute"; // Certifique-se de ter a rota protegida
import ProcessamentoOportunidades from "./pages/ProcessamentoOportunidades";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route path="listusers" element={<UserList />} />
            <Route path="listsolic" element={<SolicList />} />
            <Route path="me" element={<Me />} />
            <Route path="edituser" element={<EditUser />} />
            <Route path="posicaoall" element={<PosicaoAll />} />
            <Route path="posicaoallPeriodo" element={<PosicaoAllPeriodo />} />
            <Route path="posicaoaberto" element={<PosicaoAberto />} />
            <Route path="posicaocomprar" element={<PosicoesComprar />} />
            <Route path="solic" element={<CreateSolic />} />
            <Route path="pairs" element={<ListPairs />} />
            <Route path="oportunidades" element={<ProcessamentoOportunidades />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
